/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import WPGBlocks from 'react-gutenberg';
import { parse } from '@wordpress/block-serialization-default-parser';
import { MapBlocks } from '@blocks/MapBlocks/MapBlocks';

// Styles for static blocks
import '../blocks/info-box/style.scss';

const PostContent = (props) => {
    const { content, contentRaw, reusableBlocks } = props;

    // console.log('contentRaw', contentRaw);
    const parsedContent = parse(contentRaw ?? '');
    return (
        <>
            <WPGBlocks
                blocks={parsedContent}
                mapToBlock={(name) => {
                    return MapBlocks(name, reusableBlocks, parsedContent, content);
                }}
            />
        </>
    )
};

PostContent.propTypes = {
    content: PropTypes.string
};

PostContent.defaultProps = {
    content: ''
};

export default PostContent;
